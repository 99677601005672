"use strict";

(function (UtilityController) {
  // run code when dom is full loaded
  document.addEventListener("DOMContentLoaded", function () {
    createUtilityController();
    createDropdownController();
  }); // navigation

  function createUtilityController() {
    var utilityControler = new UtilityController();
    utilityControler.init();
  }

  function createDropdownController() {
    var dropdownArray = document.querySelectorAll(".dropdown-element");

    if (dropdownArray.length > 0) {
      Array.from(dropdownArray).forEach(function (dropdown) {
        var dropdownController = new DropdownController(dropdown);
        dropdownController.init();
      });
    }
  }
})(UtilityController, DropdownController);